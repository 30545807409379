<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-row>
          <v-col cols="9" md="11"
            ><v-card-title
              >Daily Category Sales 
              <span>({{ dateString }})</span></v-card-title
            ></v-col
          >
          <v-col cols="3" md="1">
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" class="mt-3" icon>
                  <v-icon>mdi-calendar</v-icon>
                </v-btn>
              </template>
              <v-date-picker v-model="date" range scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="$refs.dialog.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="5" md="3" class="ml-4">
            <v-select
              class="mt-3"
              v-model="selectBranch"
              :items="branchList"
              item-text="name"
              item-value="branch_id"
              outlined
              dense
            ></v-select
          ></v-col>
        </v-row>
        <v-row>
          <!-- <v-col>
            <div v-show="showChart">
              <apexchart :options="chartOptions" :series="series" />
            </div>
          </v-col> -->

          <v-col cols="12">
            <base-card>
              <div class="d-flex align-center justify-space-between pr-3">
                <!-- <v-card-title>Daily Category Sales Table</v-card-title> -->
                <v-spacer></v-spacer>
                <div v-if="salesList.length != 0">
                  <export-excel
                    :data="salesList"
                    :fields="json_fields"
                    :name="filename"
                  >
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"> mdi-download </v-icon>
                      </template>
                      <span>Download Category Sales Report</span>
                    </v-tooltip>
                  </export-excel>
                </div>
              </div>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="salesList"
                  :page.sync="page"
                  :items-per-page="itemsPerPage"
                  @page-count="pageCount = $event"
                  item-key="id"
                  hide-default-footer
                >
                  <template v-slot:item.category_name="{ item }">
                    <span v-if="item.category_name == ''">No Category</span>
                    <span v-else>{{ item.category_name }}</span>
                  </template>
                </v-data-table>
                <div class="text-center py-2">
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                  ></v-pagination>
                </div>
              </v-card-text>
            </base-card>
          </v-col>
        </v-row>
      </base-card>
    </v-col>
  </v-row>
</template>
    <script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import TestCard from "@/components/card/TestCard";
import PriceCard from "@/components/card/PriceCard";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "Sales",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Sales",
  },
  components: {
    "test-card": TestCard,
    "price-card": PriceCard,
    VueHtml2pdf,
  },
  data() {
    return {
      json_fields: {
        id: "id",
        Branch: "name",
        "Category Name": "category_name",
        "Amount Sold": "amount_sold",
        Sales: "total_amount",
        "Original Sales": "total_ori_amount",
        // Date: "date",
      },
      domain: BASEURL,
      company_id: "",
      showIcon: false,
      showChart: false,
      date: [],
      modal: false,
      branchList: [],
      salesList: [],
      categoriesList: [],
      selectBranch: "",
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },

        {
          text: "Branch",
          align: "start",
          sortable: false,
          value: "name",
        },

        {
          text: "Category Name",
          align: "start",
          sortable: false,
          value: "category_name",
        },

        {
          text: "Amount sold",
          align: "start",
          sortable: true,
          value: "amount_sold",
        },

        {
          text: "Sales(with tax or promotion)",
          align: "start",
          value: "total_amount",
          sortable: true,
        },

        {
          text: "Original Sales",
          align: "start",
          value: "total_ori_amount",
          sortable: false,
        },

        // {
        //   text: "Date",
        //   align: "start",
        //   value: "date",
        //   sortable: false,
        // },
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,

      //chart
      chartOptions: {
        chart: {
          type: "bar",
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
      series: [
        {
          name: "Sales",
          data: [],
        },
      ],
    };
  },
  computed: {
    filename() {
      var moment = require("moment");
      return (
        "Daily Category Sales Report_" +
        (this.date.length > 1
          ? this.date[0] != this.date[1]
            ? moment(this.date[1]).isBefore(this.date[0])
              ? this.date[1] + " until " + this.date[0]
              : this.date[0] + " until " + this.date[1]
            : this.date[0]
          : this.date[0]) +
        ".xls"
      );
    },

    dateString() {
      var moment = require("moment");
      return this.date.length > 1
        ? this.date[0] != this.date[1]
          ? moment(this.date[1]).isBefore(this.date[0])
            ? this.date[1] + "~" + this.date[0]
            : this.date[0] + "~" + this.date[1]
          : this.date[0]
        : this.date[0];
    },
  },
  watch: {
    date() {
      this.salesList = [];
      this.getAllSettlement();
    },
    selectBranch() {
      this.salesList = [];
      this.getAllSettlement();
    },
  },
  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getAllCategories();
    this.getCompanyBranch();
  },
  methods: {
    getAllSettlement() {
      if (this.date.length <= 1) {
        const params = new URLSearchParams();
        params.append("getCategoryPerDayList", "done");
        params.append("branch_id", this.selectBranch);
        params.append("month", this.date[0]);

        axios({
          method: "post",
          url: this.domain + "/report/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);

            if (response.data.status == "1") {
              // this.series = [
              //   {
              //     name: "Sales",
              //     data: [],
              //   },
              // ];
              // (this.chartOptions = {
              //   chart: {
              //     type: "bar",
              //     zoom: {
              //       enabled: false,
              //     },
              //   },
              //   plotOptions: {
              //     bar: {
              //       horizontal: true,
              //       endingShape: "rounded",
              //     },
              //   },
              //   dataLabels: {
              //     enabled: false,
              //   },
              //   xaxis: {
              //     categories: [],
              //   },
              // }),
              this.salesList = response.data.report;
              this.showChart = true;

              // var tempArray = [];
              // tempArray.push("no category");
              // for (let w = 0; w < this.categoriesList.length; w++) {
              //   tempArray.push(this.categoriesList[w].name);
              // }

              // for (let j = 0; j < this.salesList.length; j++) {
              //   if (this.salesList[j].category_name == "") {
              //     this.chartOptions.xaxis.categories.push("no category");
              //     this.series[0].data.push(this.salesList[j].total_amount);
              //   } else {
              //     this.chartOptions.xaxis.categories.push(
              //       this.categoriesList[j].name
              //     );
              //     this.series[0].data.push(this.salesList[j].total_amount);
              //   }
              // }

              // let difference = tempArray.filter(
              //   (x) => !this.chartOptions.xaxis.categories.includes(x)
              // );

              // for (let i = 0; i < difference.length; i++) {
              //   this.chartOptions.xaxis.categories.push(difference[i]);
              //   this.series[0].data.push(0);
              // }
            } else {
              this.salesList = [];
              // this.series = [
              //   {
              //     name: "Sales",
              //     data: [],
              //   },
              // ];

              // this.chartOptions = {
              //   chart: {
              //     type: "bar",
              //     zoom: {
              //       enabled: false,
              //     },
              //   },
              //   plotOptions: {
              //     bar: {
              //       horizontal: true,
              //       endingShape: "rounded",
              //     },
              //   },
              //   dataLabels: {
              //     enabled: false,
              //   },
              //   xaxis: {
              //     categories: [],
              //   },
              // };

              // this.chartOptions.xaxis.categories.push("no category");
              // this.series[0].data.push(0);
              // for (let w = 0; w < this.categoriesList.length; w++) {
              //   this.chartOptions.xaxis.categories.push(
              //     this.categoriesList[w].name
              //   );
              //   this.series[0].data.push(0);
              // }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        if (this.date[0] == this.date[1]) {
          const params = new URLSearchParams();
          params.append("getCategoryPerDayList", "done");
          params.append("branch_id", this.selectBranch);
          params.append("month", this.date[0]);

          axios({
            method: "post",
            url: this.domain + "/report/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);

              if (response.data.status == "1") {
                // this.series = [
                //   {
                //     name: "Sales",
                //     data: [],
                //   },
                // ];
                // (this.chartOptions = {
                //   chart: {
                //     type: "bar",
                //     zoom: {
                //       enabled: false,
                //     },
                //   },
                //   plotOptions: {
                //     bar: {
                //       horizontal: true,
                //       endingShape: "rounded",
                //     },
                //   },
                //   dataLabels: {
                //     enabled: false,
                //   },
                //   xaxis: {
                //     categories: [],
                //   },
                // }),
                this.salesList = response.data.report;
                this.showChart = true;

                // var tempArray = [];
                // tempArray.push("no category");
                // for (let w = 0; w < this.categoriesList.length; w++) {
                //   tempArray.push(this.categoriesList[w].name);
                // }

                // for (let j = 0; j < this.salesList.length; j++) {
                //   if (this.salesList[j].category_name == "") {
                //     this.chartOptions.xaxis.categories.push("no category");
                //     this.series[0].data.push(this.salesList[j].total_amount);
                //   } else {
                //     this.chartOptions.xaxis.categories.push(
                //       this.categoriesList[j].name
                //     );
                //     this.series[0].data.push(this.salesList[j].total_amount);
                //   }
                // }

                // let difference = tempArray.filter(
                //   (x) => !this.chartOptions.xaxis.categories.includes(x)
                // );

                // for (let i = 0; i < difference.length; i++) {
                //   this.chartOptions.xaxis.categories.push(difference[i]);
                //   this.series[0].data.push(0);
                // }
              } else {
                this.salesList = [];
                // this.series = [
                //   {
                //     name: "Sales",
                //     data: [],
                //   },
                // ];

                // this.chartOptions = {
                //   chart: {
                //     type: "bar",
                //     zoom: {
                //       enabled: false,
                //     },
                //   },
                //   plotOptions: {
                //     bar: {
                //       horizontal: true,
                //       endingShape: "rounded",
                //     },
                //   },
                //   dataLabels: {
                //     enabled: false,
                //   },
                //   xaxis: {
                //     categories: [],
                //   },
                // };

                // this.chartOptions.xaxis.categories.push("no category");
                // this.series[0].data.push(0);
                // for (let w = 0; w < this.categoriesList.length; w++) {
                //   this.chartOptions.xaxis.categories.push(
                //     this.categoriesList[w].name
                //   );
                //   this.series[0].data.push(0);
                // }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          var moment = require("moment");
          var firstDate = "";
          var secondDate = "";
          if (moment(this.date[1]).isBefore(this.date[0])) {
            firstDate = this.date[1];
            secondDate = this.date[0];
          } else {
            firstDate = this.date[0];
            secondDate = this.date[1];
          }

          const params = new URLSearchParams();
          params.append("getCategoryPerDayRangeList", "done");
          params.append("branch_id", this.selectBranch);
          params.append("first_date", firstDate);
          params.append("second_date", secondDate);

          axios({
            method: "post",
            url: this.domain + "/report/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);

              if (response.data.status == "1") {
                // this.series = [
                //   {
                //     name: "Sales",
                //     data: [],
                //   },
                // ];
                // (this.chartOptions = {
                //   chart: {
                //     type: "bar",
                //     zoom: {
                //       enabled: false,
                //     },
                //   },
                //   plotOptions: {
                //     bar: {
                //       horizontal: true,
                //       endingShape: "rounded",
                //     },
                //   },
                //   dataLabels: {
                //     enabled: false,
                //   },
                //   xaxis: {
                //     categories: [],
                //   },
                // }),
                this.salesList = response.data.report;
                this.showChart = true;

                // var tempArray = [];
                // tempArray.push("no category");
                // for (let w = 0; w < this.categoriesList.length; w++) {
                //   tempArray.push(this.categoriesList[w].name);
                // }

                // for (let j = 0; j < this.salesList.length; j++) {
                //   if (this.salesList[j].category_name == "") {
                //     this.chartOptions.xaxis.categories.push("no category");
                //     this.series[0].data.push(this.salesList[j].total_amount);
                //   } else {
                //     this.chartOptions.xaxis.categories.push(
                //       this.categoriesList[j].name
                //     );
                //     this.series[0].data.push(this.salesList[j].total_amount);
                //   }
                // }

                // let difference = tempArray.filter(
                //   (x) => !this.chartOptions.xaxis.categories.includes(x)
                // );

                // for (let i = 0; i < difference.length; i++) {
                //   this.chartOptions.xaxis.categories.push(difference[i]);
                //   this.series[0].data.push(0);
                // }
              } else {
                this.salesList = [];
                // this.series = [
                //   {
                //     name: "Sales",
                //     data: [],
                //   },
                // ];

                // this.chartOptions = {
                //   chart: {
                //     type: "bar",
                //     zoom: {
                //       enabled: false,
                //     },
                //   },
                //   plotOptions: {
                //     bar: {
                //       horizontal: true,
                //       endingShape: "rounded",
                //     },
                //   },
                //   dataLabels: {
                //     enabled: false,
                //   },
                //   xaxis: {
                //     categories: [],
                //   },
                // };

                // this.chartOptions.xaxis.categories.push("no category");
                // this.series[0].data.push(0);
                // for (let w = 0; w < this.categoriesList.length; w++) {
                //   this.chartOptions.xaxis.categories.push(
                //     this.categoriesList[w].name
                //   );
                //   this.series[0].data.push(0);
                // }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },

    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranch", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            var moment = require("moment");
            this.branchList = response.data.branch;
            this.selectBranch = this.branchList[0].branch_id;
            this.date.push(moment().format("YYYY-MM-DD"));
            this.getAllSettlement();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAllCategories() {
      const params = new URLSearchParams();
      params.append("getAllCategories", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/categories/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.categoriesList = response.data.categories;
            this.showChart = true;

            // this.chartOptions.xaxis.categories.push("no category");
            // this.series[0].data.push(0);
            // for (let w = 0; w < this.categoriesList.length; w++) {
            //   this.chartOptions.xaxis.categories.push(
            //     this.categoriesList[w].name
            //   );
            //   this.series[0].data.push(0);
            // }
          } else {
            this.categoriesList = [];
            this.showChart = true;
            // this.chartOptions.xaxis.categories.push("no category");
            // this.series[0].data.push(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
    <style>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: large;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
</style>
    
    
    